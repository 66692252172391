<template>
  <div class="confirm-modal-second">
    <slot name="description" />
    <InputTextarea
      @click.native="error = []"
      :set-value="formDataToSend"
      :id="'reasonForCancellation'"
      :label="'reasonForCancellation'"
      :placeholder="'Ввести текст'"
      :error-messages="error"
      isBordered
    />
    <div class="confirm-modal-second__buttons-container">
      <ButtonMain :buttonCancel="true" @click.native="onCancel" class="confirm-modal-second__btn">
        <slot name="cancel" />
      </ButtonMain>
      <ButtonMain class="confirm-modal-second__btn" @click.native="handleOnSuccess">
        <slot name="success" />
      </ButtonMain>
    </div>
  </div>
</template>

<script>
import ButtonMain from "../atoms/buttons/ButtonMain.vue";
import InputTextarea from "../atoms/inputs/InputTextarea.vue";
export default {
  name: "ConfirmModalSecond",
  data() {
    return {
      formDataToSend: {
        reasonForCancellation: null,
      },
      error: [],
    };
  },
  components: {
    ButtonMain,
    InputTextarea,
  },
  props: {
    onCancel: {
      type: Function,
      default() {
        return {};
      },
    },
    onSuccess: {
      type: Function,
      default() {
        return {};
      },
    },
  },
  methods: {
    handleOnSuccess() {
      if (this.formDataToSend.reasonForCancellation != null) {
        this.onSuccess(this.formDataToSend.reasonForCancellation);
      } else {
        this.error = ["Напишите причину отказа!"];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm-modal-second {
  @include flex-column;

  justify-content: center;
  align-items: center;
  width: 346px;
  margin: auto;

  p {
    font-size: $g-font-base-size;
    color: $g-color-white;
    padding-bottom: 15px;
    text-align: center;
    font-weight: normal;
  }

  &__buttons-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  /deep/.button-main {
    width: 150px;
    height: 36px;
  }
  /deep/.textarea-input {
    width: 100%;
  }
}
</style>
