<template>
  <Main>
    <div class="order-detail" v-if="order != null">
      <div class="order-detail__container">
        <div class="order-detail__header">
          <div class="order-detail__button-back">
            <ButtonMain @click.native="backToList" :buttonCancel="true">Назад к списку</ButtonMain>
          </div>
          <div class="order-detail__date-registr">Дата регистрации: {{ dateRegistr }}</div>
        </div>
        <div class="order-detail__number-order">Заказ {{ order.id }}</div>
        <div class="order-detail__content-container">
          <div class="order-detail__left">
            <div
              :class="[
                'order-detail__status',
                order.status.key === 'completed' ||
                order.status.key === 'completed_by_driver' ||
                order.status.key === 'completed_by_client' ||
                order.status.key === 'completed_time_out' ||
                order.status.key === 'completed_by_dispatcher'
                  ? ''
                  : order.status.key === 'placed' ||
                    order.status.key === 'active' ||
                    order.status.key === 'set' ||
                    order.status.key === 'today' ||
                    order.status.key === 'suggesting' ||
                    order.status.key === 'waiting_dispatcher' ||
                    order.status.key === 'moderated_dispatcher' ||
                    order.status.key === 'waiting_dispatcher' ||
                    order.status.key === 'moderated_dispatcher'
                  ? 'green'
                  : 'red',
              ]"
            >
              {{ order.status.title }}
            </div>
            <div
              class="order-detail__button-group"
              v-if="
                order.status.key != 'canceled' &&
                order.status.key != 'canceled_by_client' &&
                order.status.key != 'canceled_by_dispatcher' &&
                order.status.key != 'completed_by_driver' &&
                order.status.key != 'completed_by_client' &&
                order.status.key != 'completed_time_out' &&
                order.status.key != 'completed_by_dispatcher' &&
                order.status.key != 'completed'
              "
            >
              <ButtonMain
                v-if="
                  order.driver === null &&
                  order.status.key != 'expired' &&
                  order.status.key != 'moderated_dispatcher' &&
                  order.status.key != 'waiting_dispatcher'
                "
                @click.native="assignDriverToOrder()"
              >
                Назначить водителя
              </ButtonMain>
              <ButtonMain
                :buttonDark="true"
                v-if="order.driver !== null && order.status.key != 'expired'"
                @click.native="reAssignDriverToOrder()"
              >
                Переназначить водителя
              </ButtonMain>
              <ButtonMain
                buttonDark
                v-if="order.driver !== null && order.status.key != 'expired'"
                @click.native="handleOpenRemoveDriverModal()"
              >
                Снять водителя
              </ButtonMain>
              <ButtonMain
                v-if="order.driver === null"
                @click.native="orderUpdate()"
                :buttonCancel="true"
                >Редактировать</ButtonMain
              >
              <ButtonMain
                v-if="order.status.key === 'active'"
                @click.native="handleOpenFinishModal()"
                :buttonDark="true"
                >Завершить заказ</ButtonMain
              >
              <ButtonMain
                v-if="order.status.key != 'expired'"
                @click.native="handleCancelOrder()"
                :buttonCancel="true"
              >
                Отменить заказ
              </ButtonMain>
              <ButtonMain
                v-if="
                  order.status.key != 'active' &&
                  order.status.key != 'waiting_dispatcher' &&
                  order.status.key != 'moderated_dispatcher'
                "
                @click.native="handleOpenSendFinishModal()"
                :buttonDark="true"
                >Назначить завершенным</ButtonMain
              >
            </div>
            <div class="order-detail__content">
              <ListItem :title="'Имя заказчика:'" :value="order.client.name" />
              <ListItem :title="'Телефон заказчика:'" :value="order.client.phone" />
              <ListItem :title="'Откуда:'" :value="order.addressFromShort" />
              <ListItem :title="'Куда:'" :value="order.addressToShort" />
              <ListItem :title="'Наименование груза:'" :value="order.cargoName" />
              <ListItem :title="'Дата:'" :value="order.date" />
              <ListItem :title="'Время:'" :value="order.time" />
              <ListItem :title="'Количество часов:'" :value="order.countOfHours" />
              <ListItem :title="'Тариф, грн/час:'" :value="order.rate" />
              <ListItem :title="'Цена, грн:'" :value="order.totalPrice" />
              <ListItem :title="'Страховка:'" :value="order.insurance ? 'есть' : 'нет'" />
              <template v-if="order.insurance">
                <ListItem :title="'Сумма страховки:'" :value="order.costEstimate" />
              </template>
              <ListItem :title="'Грузчики:'" :value="order.numberOfMovers || 'нет'" />
              <ListItem :title="'Описание:'" :value="order.description" />
              <ListItem :title="'Особенности груза:'" :value="cargoFeatures" />
              <template v-if="order.bodyType">
                <ListItem :title="'Дополнительные характеристики:'" :value="order.bodyType.name" />
              </template>
              <template v-else>
                <ListItem :title="'Дополнительные характеристики:'" :value="'нет'" />
              </template>
              <ListItem :title="'Тип авто:  '" :value="typeOfCar" />
              <ListItem :title="'Тип загрузки:  '" :value="loadings" />
              <template v-if="order.paymentType != null">
                <ListItem :title="'Тип оплаты:  '" :value="order.paymentType.name" />
              </template>
              <ListItem
                v-if="order.links.length > 0"
                :title="'Ccылка:  '"
                :value="'Ссылка на карту'"
                :linkUrl="order.links[0].url"
                link
              />
            </div>
            <div v-if="order.driver != null" class="order-detail__content driver">
              <ListItem
                :title="'Водитель:'"
                :value="`${order.driver.lastName} ${order.driver.firstName} ${order.driver.patronymic}`"
              />
              <ListItem :title="'Телефон:'" :value="order.driver.phone" />
            </div>
          </div>
          <div v-if="orderHistory.length > 0" class="order-detail__right">
            <div class="order-detail__history">
              <div class="order-detail__history-title">История операций с заказом</div>
              <div v-for="item in orderHistory" :key="item.id" class="order-detail__history-item">
                <div class="order-detail__history-item-date">
                  <span>{{ item.createdAt }}</span>
                  <span
                    @click="handleGoToDriver(item.driver.id)"
                    v-if="item.driver != null"
                    class="order-detail__history-item-id"
                    >{{ item.driver.id }}</span
                  >
                </div>
                <div>
                  {{ item.message }} <br />
                  Статус: {{ item.order.status.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DefaultModal
      class="order-detail__send-finish-modal"
      v-if="sendFinishModal"
      :on-close="handleCloseSendFinishModal"
      modalCenter
    >
      <div class="order-detail__send-finish-header">
        <div class="order-detail__send-finish-title">Назначить водителя</div>
        <div class="order-detail__send-finish-search">
          <InputSearch
            :label="'search'"
            :search="searchDrivers"
            :placeholder="'Введите ФИО или телефон водителя'"
          />
        </div>
      </div>
      <ScrollList>
        <CustomTable
          :data="driversList"
          :deleted-keys="tableOptions.deletedKeys"
          :rename-columns="tableOptions.renameColumn"
          :custom-order="tableOptions.customOrder"
          :is-action-block-visible="tableOptions.isActionBlockVisible"
          :direction="directionSort"
          :order="orderSort"
          :handleFilter="handleFilterDriver"
          :handleButton="handleAssignDriver"
          :name="'driver'"
        />
      </ScrollList>
    </DefaultModal>

    <DefaultModal
      class="order-detail__confirm-modal"
      v-if="cancelModal"
      :on-close="handleCloseConfirmModal"
    >
      <ConfirmModalSecond :on-cancel="handleCloseConfirmModal" :on-success="handleCancel">
        <template v-slot:description>
          <p>Укажите причину отмены заказа</p>
        </template>
        <template v-slot:cancel> Не отменять </template>
        <template v-slot:success> Отменить заказ </template>
      </ConfirmModalSecond>
    </DefaultModal>
    <DefaultModal
      class="order-detail__confirm-modal"
      v-if="finishModal"
      :on-close="handleCloseFinishModal"
    >
      <ConfirmModalContent :on-cancel="handleCloseFinishModal" :on-success="handleFinishOrder">
        <template v-slot:description>
          <p>Вы уверены, что хотите завершить заказ?</p>
        </template>
        <template v-slot:cancel> Отмена </template>
        <template v-slot:success> Да </template>
      </ConfirmModalContent>
    </DefaultModal>
    <DefaultModal
      class="order-detail__confirm-modal"
      v-if="sendFinishModalWithDriver"
      :on-close="handleCloseSendFinishModalWithDriver"
    >
      <ConfirmModalContent
        :on-cancel="handleCloseSendFinishModalWithDriver"
        :on-success="handleAssignDriver"
      >
        <template v-slot:description>
          <p>Вы уверены, что хотите назначить завершенным заказ?</p>
        </template>
        <template v-slot:cancel> Отмена </template>
        <template v-slot:success> Да </template>
      </ConfirmModalContent>
    </DefaultModal>
    <DefaultModal
      class="order-detail__confirm-modal"
      v-if="removeDriverModal"
      :on-close="handleCloseRemoveDriverModal"
    >
      <ConfirmModalContent
        :on-cancel="handleCloseRemoveDriverModal"
        :on-success="handleRemoveDriver"
      >
        <template v-slot:description>
          <p>Вы уверены, что хотите снять водителя с заказа?</p>
        </template>
        <template v-slot:cancel> Отмена </template>
        <template v-slot:success> Да </template>
      </ConfirmModalContent>
    </DefaultModal>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import { dateTransform } from "~/helpers/dateHelper";
import ROUTE from "~/constants/routes";
import ordersApi from "~/api/order";

import Main from "~/templets/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ButtonMain from "~/components/atoms/buttons/ButtonMain.vue";
import ListItem from "~/components/molecules/ListItem.vue";
import DefaultModal from "~/components/molecules/DefaultModal.vue";
import ConfirmModalSecond from "~/components/molecules/ConfirmModalSecond.vue";
import ConfirmModalContent from "~/components/molecules/ConfirmModalContent.vue";
import CustomTable from "~/components/molecules/CustomTable.vue";
import ScrollList from "~/components/atoms/ScrollList.vue";
import InputSearch from "~/components/atoms/inputs/InputSearch.vue";

export default {
  name: "OrderDetail",
  data() {
    return {
      cancelModal: false,
      finishModal: false,
      sendFinishModal: false,
      sendFinishModalWithDriver: false,
      removeDriverModal: false,
      directionSort: false,
      search: "",
      orderSort: "id",
      tableOptions: {
        deletedKeys: ["status"],
        isActionBlockVisible: true,
        renameColumn: [
          {
            from: "id",
            to: "ID",
          },
          {
            from: "last_name",
            to: "ФИО",
          },
          {
            from: "phone",
            to: "Телефон",
          },
          {
            from: "type_of_car_id",
            to: "Тип авто",
          },
          {
            from: "free",
            to: "Статус",
          },
        ],
        customOrder: ["id", "last_name", "phone", "type_of_car_id", "free", "status"],
      },
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ButtonMain,
    ListItem,
    DefaultModal,
    ConfirmModalSecond,
    ConfirmModalContent,
    ScrollList,
    CustomTable,
    InputSearch,
  },
  methods: {
    handleOpenSendFinishModal() {
      if (this.order.driver === null) {
        this.sendFinishModal = true;
      } else {
        this.sendFinishModalWithDriver = true;
      }
    },
    handleCloseSendFinishModal() {
      this.sendFinishModal = false;
    },
    handleCloseSendFinishModalWithDriver() {
      this.sendFinishModalWithDriver = false;
    },
    searchDrivers(search) {
      this.search = search;
      this.getDriversList();
    },
    handleFilterDriver(column) {
      this.orderSort = column;
      if (this.directionSort === null) {
        this.directionSort = true;
      } else {
        this.directionSort = !this.directionSort;
      }
      this.getDriversList();
    },
    handleAssignDriver(row = false) {
      const data = {
        order_id: this.order.id,
      };
      if (this.order.driver === null) {
        data.driver_id = row.id;
      }
      this.$store.commit("order/setLoading", true);
      ordersApi
        .sendFinishOrder(data)
        .then((res) => {
          console.log(res);
          this.sendFinishModal = false;
          this.sendFinishModalWithDriver = false;
          this.getOrderDetail();
        })
        .catch((error) => {})
        .finally(() => {
          this.$store.commit("order/setLoading", false);
        });
    },
    getDriversList() {
      let url = ``;
      if (this.orderSort.length) {
        url = url + `?order=${this.orderSort}&direction=${this.directionSort ? "asc" : "desc"}`;
      }
      if (this.search.length) {
        url = url + `&search=${this.search}`;
      }
      this.$store.dispatch("drivers/getDriversList", url);
    },
    getOrderDetail() {
      const { params } = this.$route;
      const url = `/${params.orderId}`;
      this.$store.dispatch("order/getOrderDetail", url);
    },
    backToList() {
      this.$router.push({
        path: ROUTE.ORDERS_PAGE.replace(":page", 1),
      });
    },
    handleCancel(data) {
      const url = `/${this.order.id}`;
      const dataSend = {
        reason_for_cancellation: data,
      };
      this.$store.dispatch("order/cancelOrder", { url: url, dataSend: dataSend }).finally(() => {
        this.getOrderDetail();
      });
      this.handleCloseConfirmModal();
    },
    handleFinishOrder() {
      const id = `${this.order.id}`;
      this.$store.dispatch("order/finishOrder", id).finally(() => {
        this.getOrderDetail();
      });
      this.handleCloseFinishModal();
    },
    handleRemoveDriver() {
      const id = `${this.order.id}`;
      this.$store.dispatch("order/removeDriver", id).finally(() => {
        setTimeout(() => {
          this.getOrderDetail();
        }, 1000);
      });
      this.handleCloseRemoveDriverModal();
    },
    handleCloseFinishModal() {
      this.finishModal = false;
    },
    handleOpenFinishModal() {
      this.finishModal = true;
    },
    handleCloseRemoveDriverModal() {
      this.removeDriverModal = false;
    },
    handleOpenRemoveDriverModal() {
      this.removeDriverModal = true;
    },
    handleCloseConfirmModal() {
      this.cancelModal = false;
    },
    handleCancelOrder() {
      this.cancelModal = true;
    },
    orderUpdate() {
      this.$router.push({
        path: ROUTE.ORDER_CREATE_PAGE.replace(":process", "update").replace(
          ":orderId",
          this.order.id,
        ),
      });
    },
    handleGoToDriver(id) {
      this.$router.push({
        path: ROUTE.DRIVER_DETAIL_PAGE.replace(":driverId", id),
      });
    },
    reAssignDriverToOrder() {
      this.$router.push({
        path: ROUTE.ETHER_ADD_DRIVER_PAGE.replace(":orderId", this.order.id),
        query: { reAssign: "true" },
      });
    },
    assignDriverToOrder() {
      this.$router.push({
        path: ROUTE.ETHER_ADD_DRIVER_PAGE.replace(":orderId", this.order.id),
      });
    },
    getOrderHistory() {
      const { params } = this.$route;
      const url = `${params.orderId}`;
      this.$store.dispatch("order/getOrderHistory", url);
    },
  },
  computed: {
    ...mapState("order", ["order", "loadingProcess", "orderHistory"]),
    ...mapState("drivers", ["driversList"]),
    dateRegistr() {
      return dateTransform(this.order.createdAt);
    },
    cargoFeatures() {
      let features = "";
      if (this.order.cargoFeatures.length) {
        for (let i = 0; i < this.order.cargoFeatures.length; i++) {
          if (i === 0) {
            features += this.order.cargoFeatures[i].name;
          } else {
            features += `, ${this.order.cargoFeatures[i].name}`;
          }
        }
      } else {
        features = "нет";
      }
      return features;
    },
    loadings() {
      let loadings = "";
      if (this.order.loadings.length) {
        for (let i = 0; i < this.order.loadings.length; i++) {
          if (i === 0) {
            loadings += this.order.loadings[i].name;
          } else {
            loadings += `, ${this.order.loadings[i].name}`;
          }
        }
      } else {
        loadings = "нет";
      }
      return loadings;
    },
    typeOfCar() {
      let type = "";
      if (this.order.typeOfCar.id != 5) {
        type = `${this.order.typeOfCar.name}, ${this.order.typeOfCar.description}`;
      } else {
        type = `${this.order.typeOfCar.name},
        До ${this.order.weight} т, Д ${this.order.length} м,
        Ш ${this.order.width} м, В. ${this.order.height} м, V ${this.order.volume} м3`;
      }
      return type;
    },
  },
  mounted() {
    this.getOrderDetail();
    this.getOrderHistory();
    this.getDriversList();
  },
};
</script>

<style lang="scss" scoped>
.order-detail {
  min-width: 90%;

  &__container {
    padding: 25px 17px 25px 17px;
  }

  &__header {
    @include flex-row;

    justify-content: space-between;
  }

  &__date-registr {
    @include textGrey;

    display: flex;
    align-items: flex-end;
  }

  &__number-order {
    @include textTitle;

    padding: 20px 0;
  }

  &__button-back {
    width: 140px;
  }

  &__button-group {
    @include flex-row;

    /deep/.button-main {
      width: 178px;
      height: 48px;
      margin-right: 20px;
    }
  }

  &__content {
    padding-top: 20px;
  }
  &__content.driver {
    border-top: $mainBorder;
    margin: 20px 0;
  }

  &__status {
    font-size: 500;
    padding-bottom: 15px;
    font-weight: 500;

    &.red {
      color: #eb5959;
    }

    &.green {
      color: #5fbf68;
    }
  }

  &__content-container {
    display: flex;
    justify-content: space-between;
  }

  &__right {
    min-width: 400px;
    display: flex;
  }

  &__history {
    width: 340px;
    height: fit-content;
    margin-left: auto;
    padding: 20px;
    border-radius: 5px;
    background: #daf2d4;
  }

  &__history-title {
    font-size: 18px;
    padding-bottom: 15px;
  }

  &__history-item {
    position: relative;
    padding-left: 30px;
    padding-bottom: 15px;
    font-size: 14px;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      z-index: 1;
      top: 2px;
      width: 12px;
      height: 12px;
      border-radius: 50px;
      background: #5fbf68;
    }
  }

  &__history-item-date {
    padding-bottom: 5px;
  }

  &__history-item-id {
    padding-left: 15px;
    text-decoration: underline;
    cursor: pointer;
  }

  &__send-finish-modal {
    /deep/.modal-container {
      width: 900px !important;
    }

    /deep/.custom-table__container {
      margin: 0;
    }
  }

  &__send-finish-header {
    margin-bottom: 20px;
  }

  &__send-finish-search {
    width: 350px;
  }

  &__send-finish-title {
    margin-bottom: 15px;
  }
}

.order-detail__history-item ~ .order-detail__history-item::after {
  content: "";
  position: absolute;
  left: 5px;
  bottom: 93%;
  height: 100%;
  border-right: 1px solid #000;
}
</style>
